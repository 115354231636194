
import { defineComponent, ref, onMounted, watch } from "vue";
import BeneficiaryModal from "@/components/modals/forms/beneficiary/BeneficiaryModal.vue";
import BeneficiaryImportModal from "@/components/modals/forms/beneficiary/BeneficiaryImportModal.vue";
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import { ElNotification } from "element-plus";
import { useBus } from "@/bus.ts";
import { DrawerComponent } from "@/assets/ts/components/index";
import KTLoader from "@/components/Loader.vue";
import {Actions} from "@/store/enums/StoreEnums";
import {useStore} from "vuex";
import { useRouter } from "vue-router";
import {VueCookieNext} from "vue-cookie-next";

export default defineComponent({
  mixins: [apiEndpoint],
  name: "outPutList",
  components: {
    BeneficiaryImportModal,
    BeneficiaryModal,
    KTLoader,
  },
  setup() {
    const router = useRouter();
    const store = useStore();
    const { bus } = useBus();
    const editData = ref(null);
    const beneficiaryList = ref([]);
    const trash = ref(false);
    const loading = ref(false);
    const divisionOptions = ref([]);
    const districtOptions = ref([]);
    const upazilaOptions = ref([]);
    const iaPartnerOptions = ref([]);
    const currentPage = ref(1);
    const perPageOptions = [10, 20, 50];
    const selectedPerPage = ref(perPageOptions[0]);
    const totalRows = ref(0);
    const exportType = ref(null);
    const selectedPartner = ref(null);
    const selectedDivision = ref(null);
    const selectedDistrict = ref(null);
    const selectedUpazila = ref(null);
    const selectedGender = ref(null);
    const selectedReligion = ref(null);
    const selectedBeneficiaryPeriodtype = ref(null);
    const selectedMaritalStatus = ref(null);
    const selectedEthinicity = ref(null);
    const selectedMobileNo = ref(null);
    const selectedNid = ref(null);

    const handleSizeChange = (val) => {
      selectedPerPage.value = val;
      loadData();
    };

    const handleCurrentChange = (val) => {
      currentPage.value = val;
      loadData();
    };

    const getIaPartnerOptions = async () => {
      try {

        const id = VueCookieNext.getCookie("_ia_partner_id") !== "null"
            ? VueCookieNext.getCookie("_ia_partner_id")
            : 0;

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_IAPARTNER_LIST,
            {
              id : id
            }
        );

        iaPartnerOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.name,
        }));

      } catch (error) {
        console.error("Error fetching parent output options:", error);
      }
    };

    const getDivision = async () => {
      try {
        const response = await ApiService.get(
            apiEndpoint.data().VUE_APP_DIVISION_LIST
        );

        divisionOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.division_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDivisionWistDistrict = async () => {
      try {

        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_DISTRICT_LIST,
            {
              geo_division_id: selectedDivision.value,
            }
        );
        districtOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.district_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    const getDistrictWistUpazila = async () => {
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_UPAZILA_LIST,
            {
              geo_district_id: selectedDistrict.value,
            }
        );
        upazilaOptions.value = response.data.data.map((option) => ({
          value: option.id,
          text: option.upazila_name_eng,
        }));
      } catch (error) {
        console.error("Error fetching component options:", error);
      }
    };

    store.dispatch(Actions.ADD_BODY_CLASSNAME, "page-loading");

    const create = (data) => {
      editData.value = data ? data : null;
    };

    const edit = (id,type) => {
      router.push({ name: 'save-beneficiary', params: { id: id , type: type} });
    };

    const show = (id) => {
      const beneficiaryProfileRoute = `/beneficiary-profile/${id}`;
      window.open(beneficiaryProfileRoute, "_blank");
    };

    const deleteOutput = async (id) => {
      Swal.fire({
        title: trash.value
          ? "Are you sure you want to restore it?"
          : "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            console.log(apiEndpoint.data().VUE_APP_OUTPUT_DELETE);
            const response = await ApiService.post(
              apiEndpoint.data().VUE_APP_BENEFICIARY_DELETE,
              {
                id: id,
              }
            );

            if (response.data.status == "success") {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.data,
                type: "success",
              });
              loadData();
            } else {
              ElNotification({
                dangerouslyUseHTMLString: true,
                message: response.data.message,
                type: "error",
              });
            }
          } catch (error) {
            console.error("Error deleting output:", error);
            ElNotification({
              dangerouslyUseHTMLString: true,
              message: "Error deleting output",
              type: "error",
            });
          }
        }
      });
    };

    const loadData = async () => {
      loading.value = true;
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_BENEFICIARY_LIST,
          {
            ia_partner_id: VueCookieNext.getCookie("_ia_partner_id") != 'null'
                ? VueCookieNext.getCookie("_ia_partner_id")
                : selectedPartner.value,
            geo_division_id: selectedDivision.value,
            geo_district_id: selectedDistrict.value,
            geo_upazila_id: selectedUpazila.value,
            gender: selectedGender.value,
            religion: selectedReligion.value,
            beneficiary_ethinicity: selectedEthinicity.value,
            marital_status: selectedMaritalStatus.value,
            mobile_no: selectedMobileNo.value,
            nid: selectedNid.value,
            beneficiary_training_period_type: selectedBeneficiaryPeriodtype.value,
            page: currentPage.value,
            per_page: selectedPerPage.value,
            trash: trash.value ? 1 : 0,
          }
        );
        beneficiaryList.value = response.data.data.data;
        totalRows.value = response.data.data.total;
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
         loading.value = false;
      }
    };

    const loadTrash = () => {
      trash.value = !trash.value;
    };

    watch(trash, (newVal) => {
      loadData();
    });

    const exportChange = async () => {
      if (exportType.value == "excel") {
        exportExcel();
      }
    };

    const exportExcel = async () => {
      loading.value = true;
      try {
        const response = await ApiService.post(
            apiEndpoint.data().VUE_APP_EXPORT_BENEFICIARY_LIST,
            {}
        );

        if (response.data.status === "success" && response.data.data) {
          const downloadLink = document.createElement("a");
          downloadLink.href = apiEndpoint.data().BEE_FILE_URL+response.data.data;
          console.log(downloadLink.href);
          downloadLink.target = "_blank";
          downloadLink.download = "event_list.xlsx";
          document.body.appendChild(downloadLink);
          downloadLink.click();
          document.body.removeChild(downloadLink);
        } else {
          console.error("Invalid response for export:", response);
        }
      } catch (error) {
        console.error("Error exporting Excel:", error);
      } finally {
        loading.value = false;
      }
    };

    const downloadImportTemplate = () => {
      const anchor = document.createElement("a");
      anchor.href = apiEndpoint.data().BEE_FILE_URL + '/storage/import_template/Beneficiary_Template.xlsx';
      console.log(anchor.href);
      anchor.target = '_blank';
      anchor.download = 'beneficiary_template.xlsx';
      document.body.appendChild(anchor);
      anchor.click();
      document.body.removeChild(anchor);
    };

    onMounted(() => {
      DrawerComponent.bootstrap();
      DrawerComponent.updateAll();

      getIaPartnerOptions();
      loadData();

      getDivision();

      bus.on("loadData", async () => {
        loadData();
      });
    });

    return {
      editData,
      beneficiaryList,
      trash,
      loadTrash,
      create,
      edit,
      show,
      deleteOutput,
      loading,
      iaPartnerOptions,
      divisionOptions,
      districtOptions,
      upazilaOptions,
      getDivisionWistDistrict,
      getDistrictWistUpazila,
      loadData,
      currentPage,
      perPageOptions,
      selectedPerPage,
      totalRows,
      handleSizeChange,
      handleCurrentChange,
      downloadImportTemplate,
      exportChange,
      exportType,
      selectedPartner,
      selectedDivision,
      selectedDistrict,
      selectedUpazila,
      selectedGender,
      selectedReligion,
      selectedBeneficiaryPeriodtype,
      selectedMaritalStatus,
      selectedEthinicity,
      selectedMobileNo,
      selectedNid,
    };
  },
});
